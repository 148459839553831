/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Image from './../../image';

import './style.scss';

/**
 * ModerationSafetyFeaturedImage component.
 */
const ModerationSafetyFeaturedImage = ( { featuredImage, mobileFeaturedImage } ) => {

	return(
		<section id="moderation-safety-featured" className="moderation-safety-featured__image-container">
			<div className="hide-for-small-only">
				<Image { ...featuredImage.node } className="moderation-safety-featured__image" />
			</div>
			<div className="hide-for-medium">
				<Image { ...mobileFeaturedImage } className="moderation-safety-featured__image" />
			</div>
		</section>
	);

};

ModerationSafetyFeaturedImage.propTypes = {
	featuredImage: PropTypes.object,
	mobileFeaturedImage: PropTypes.object,
};

ModerationSafetyFeaturedImage.defaultProps = {
	featuredImage: {
		node: {
			altText: "",
			databaseId: "",
			sourceUrl: "",
			title: "",
			mediaDetails: {
				height: "",
				width: "",
			}
		}
	},
	mobileFeaturedImage: {
		altText: "",
		databaseId: "",
		sourceUrl: "",
		title: "",
		mediaDetails: {
			height: "",
			width: "",
		}
	},
};

export default ModerationSafetyFeaturedImage;
