/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from './../../../utils/functions';

import './style.scss';

/**
 * ModerationSafetyHeading component.
 *
 * @param {string} title Page title.
 */
const ModerationSafetyHeading = ( { title } ) => {
	return (
		<>
			{ title && <h1 className="moderation-safety__title" dangerouslySetInnerHTML={ { __html: sanitize( title ) } } /> }
		</>
	);
};

ModerationSafetyHeading.propTypes = {
	title: PropTypes.string,
};

ModerationSafetyHeading.defaultProps = {
	title: ''
};

export default ModerationSafetyHeading;
