/**
 * External dependencies.
 */
import React from 'react';

/**
 * ArrowBlueDown component.
 *
 * @return {jsx}
 */
const ArrowBlueDown = () => {

	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="33" viewBox="0 0 24 33">
			<g fill="none" fillRule="evenodd">
				<g fill="#0058FE" fillRule="nonzero">
					<g>
						<path d="M401.16 28.12L417.6 16.8 417.6 16.32 401.16 5 401.16 7.08 411.8 15.72 386 15.72 386 17.4 411.8 17.4 401.16 26.04z" transform="translate(-540 -2113) translate(150 2112.84) rotate(90 401.8 16.56)"/>
					</g>
				</g>
			</g>
		</svg>
	);

};

export default ArrowBlueDown;
