/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import DataFeatures from '../../components/data/features';
import ModerationSafetyHeading from './../../components/quality-moderation-and-safety/heading';
import ModerationSafetyExcerpt from './../../components/quality-moderation-and-safety/excerpt';
import ModerationSafetyFeaturedImage from './../../components/quality-moderation-and-safety/image';
import ModerationSafetyList from './../../components/quality-moderation-and-safety/modertion-safety-list';
import ConversationAdvertForm from '../../components/conversation-based-advertising/form';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import FooterCTA from './../../components/footer-cta';

import "./style.scss";

/**
 * Data Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Data = (props) => {
	const {
		pageContext: {
			page,
			page: {
				uri,
				seo,
				dataPageMeta: {
					insightsSection,
					dataFeatureList,
					mobileFeaturedImage,
					video
				},
				contactUsFormMeta: { contactForm, ctaLink },
				footerCtaLinks: { footerCtaWrapper },
				featuredImage,
			}
		}
	} = props;

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title="Data" uri={uri} className="archive archive-single data-page">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<ModerationSafetyHeading title={page.title} />
			<ModerationSafetyExcerpt excerpt={page.excerpt} ctaLink={ctaLink} tagParent="PUBLISHERS" tagName="FirstParty_Contact" />
			<ModerationSafetyExcerpt excerpt={page.content} />
			<ModerationSafetyFeaturedImage featuredImage={featuredImage} mobileFeaturedImage={mobileFeaturedImage} />
			<DataFeatures lists={dataFeatureList} />
			<ModerationSafetyList lists={insightsSection} page="data-insights" video={video} />
			{footerCtaWrapper && <section className="data-cta">
				<FooterCTA links={footerCtaWrapper} />
			</section>}
			<ConversationAdvertForm meta={contactForm} tagParent="PUBLISHERS" tagName="FirstParty_Submit" />
		</Layout>
	)
};

Data.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Data;
