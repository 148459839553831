/**
 * External dependencies.
 */
import React from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Image from '../../image';
import Video from '../../video'
import { sanitize } from '../../../utils/functions';
import { useDevice } from '../../../hooks';
import classnames from 'classnames';

import './style.scss';

/**
 * ModerationSafetyList component.
 */
const ModerationSafetyList = ( { lists, page, video } ) => {

	const device = useDevice();
	const isMobile = 'desktop' !== device && 'tablet' !== device ;
	const [ ref, inView ] = useInView( {
		threshold: 0.4,
		triggerOnce: true,
	} );

	return(
		<section className="moderation-safety-lists">
		{
			lists.map( ( card, index ) => {

				const sectionImage = isMobile ? card.mobileImage : card.desktopImage;

				let dataInsightColumnClass = 'small-12 columns';
				if ( 'data-insights' === page ) {
					if ( video && 2 === index ) {
						dataInsightColumnClass = classnames( dataInsightColumnClass, 'large-10', 'medium-10' );
					} else {
						dataInsightColumnClass = classnames( dataInsightColumnClass, 'large-8', 'medium-8' );
					}
				}

				return(
					<div className="row align-center moderation-safety-list__container" key={`moderation-safety-list-${ index }`}>
						<div className="large-8 medium-8 small-12 columns">
							<hr className="hide-for-medium" />
							<h4 className="moderation-safety-list__title" dangerouslySetInnerHTML={ { __html: sanitize( card.title ) } } />
							<div className="moderation-safety-list__content" dangerouslySetInnerHTML={ { __html: sanitize( card.description ) } } />
						</div>
						<div className={ dataInsightColumnClass }>
							{ 2 === index && 'data-insights' === page && video ? (
								<div className="data-insights__video-container" ref={ ref }>
									<Video
										meta={ video }
										device={ device }
										loop={ true }
										muted={ true }
										play={ inView }
										className={ `data-insights-lottie-container` }
									/>
								</div>
							) : (
								<div className="moderation-safety-list__image-container">
									<Image { ...sectionImage } className="moderation-safety-list__image" />
								</div>
							) }

						</div>
					</div>
				);

			} )
		}
		</section>

	);

};

ModerationSafetyList.propTypes = {
	lists: PropTypes.array,
	page: PropTypes.string,
	video: PropTypes.object,
};

ModerationSafetyList.defaultProps = {
	lists: [
		{
			title: '',
			description: '',
			mobileImage: {
				altText: "",
				databaseId: "",
				sourceUrl: "",
				title: "",
				mediaDetails: {
					height: "",
					width: "",
				}
			},
			desktopImage: {
				altText: "",
				databaseId: "",
				sourceUrl: "",
				title: "",
				mediaDetails: {
					height: "",
					width: "",
				}
			},
		}
	],
	page: '',
	video: {
		mobile: {
			posterImage: {},
			video: {},
		},
		desktop: {
			posterImage: {},
			video: {},
		}
	}
};

export default ModerationSafetyList;
