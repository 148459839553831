/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';
import ArrowBlueDown from './../../icons/arrow-blue-down';

import './style.scss';

/**
 * Data Features Component.
 *
 * @param {Object} meta Meta.
 *
 * @return {jsx}
 */
const DataFeatures = ( { lists } ) => {

	return (
		<section className="data-sections data-feature">
			<div className="row data-feature__list">
				{ lists.map( ( data, index ) => (
					<div className="large-4 medium-6 small-12 columns" key={ `${ data.title }-${ index }` }>
						<ArrowBlueDown />
						<h2 className="data-feature__heading" dangerouslySetInnerHTML={ { __html: sanitize( data.title ) } } />
					</div>
				) ) }
			</div>
		</section>
	);

};

DataFeatures.propTypes = {
	lists: PropTypes.array,
};

DataFeatures.defaultProps = {
	lists: [
		{
			title: ''
		}
	]
};

export default DataFeatures;
