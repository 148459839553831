/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { sanitize } from './../../../utils/functions';
import Link from '../../link';

import './style.scss';

const ModerationSafetyExcerpt = ( { excerpt, ctaLink, tagParent, tagName } ) => {

	return(
		<section id="moderation-safety-excerpt">
			<div className="row">
				{
					excerpt && <div className="large-8 medium-12 small-11 columns moderation-safety__excerpt-container">
						<div className="moderation-safety__excerpt" dangerouslySetInnerHTML={ { __html: sanitize( excerpt ) } } />
					</div>
				}
			</div>
			{
				ctaLink.title && ctaLink.url &&  (
					<div className="row">
						<div className="large-4 medium-4 small-12 columns">
							<Link { ...ctaLink } tagParent={ tagParent } tagName={ tagName } className="conversation-content__cta" />
						</div>
					</div>
				)
			}

		</section>
	);

};

ModerationSafetyExcerpt.propTypes = {
	excerpt: PropTypes.string,
	ctaLink: PropTypes.object,
	tagParent: PropTypes.string,
	tagName: PropTypes.string,
};

ModerationSafetyExcerpt.defaultProps = {
	excerpt: '',
	ctaLink: {
		title: '',
		url: '',
		target: ''
	},
	tagParent: '',
	tagName: '',
};

export default ModerationSafetyExcerpt;
