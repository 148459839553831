/**
 * External dependencies.
 */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import './style.scss';

/**
 * Video Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Video = (props) => {

	const {
		meta,
		className,
		device,
		muted = true,
		controls = false,
		play = false,
		loop = true,
		frameSrc = '',
	} = props;

	console.log('video');

	const videoRef = useRef();

	useEffect(() => {
		if (videoRef.current) {
			if (play) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		}
	}, [play]); // eslint-disable-line

	if (isEmpty(meta) || isEmpty(meta.mobile)) {
		return null;
	}

	const deviceType = 'desktop' === device ? device : 'mobile';
	const video = meta[deviceType].video;
	const image = meta[deviceType].posterImage;

	const videoAttributes = {
		src: video.mediaItemUrl,
		controls: controls,
		muted: muted,
		loop: loop,
		playsInline: true,
		className: 'ow-video-player'
	};

	if (image && image.sourceUrl) {
		videoAttributes.poster = image.sourceUrl;
	}

	return (
		<div className={classnames('core-video-player-container', className)}>
			{frameSrc ? <img className="ow-video-player__frame" src={frameSrc} alt="Video IPhone Frame" /> : null}
			{'undefined' !== typeof window ? (
				<video ref={videoRef} {...videoAttributes} > { /* eslint-disable-line */}
					Video not supported in your browser.
				</video>
			) : null}
		</div>
	);
};

Video.propTypes = {
	meta: PropTypes.object,
};

Video.defaultProps = {
	meta: {
		mobile: {
			posterImage: {},
			video: {},
		},
		desktop: {
			posterImage: {},
			video: {},
		}
	}
};

export default Video;
